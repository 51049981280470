
import { defineComponent } from 'vue';
import AccountingInfoDetails from '@/components/Accounting/AccountingInfo/AccountingInfoDetails.vue';
import AccountingInfoHeader from '@/components/Accounting/AccountingInfo/AccountingInfoHeader.vue';
import MobileFooterActions from '@/components/MobileFooterActions.vue';
import router from '@/router';

export default defineComponent({
    components: {
        AccountingInfoDetails,
        AccountingInfoHeader,
        MobileFooterActions
    },
    props: {
        tab: {
            type: Number,
            required: true
        }
    },
    async setup(props) {
        function back() {
            router.back();
        }
        function edit() {
            router.push({ name: 'AccountingInfoEditView', params: { tab: props.tab } });
        }

        function onTabChanged(index: number) {
            router.replace({ name: 'AccountingInfoView', params: { tab: index } });
        }

        return {
            back,
            edit,
            onTabChanged
        };
    }
});
